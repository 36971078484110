import React from "react"
import imgPortadaEbook from "@components/pageEbook/ebooks/ebook16/image/imgPortadaEbook.png"
import img1 from "@components/pageEbook/ebooks/ebook16/image/imgCard1.svg"
import img2 from "@components/pageEbook/ebooks/ebook16/image/imgCard2.svg"
import img3 from "@components/pageEbook/ebooks/ebook16/image/imgCard3.svg"
import slider1 from "@components/pageEbook/ebooks/ebook16/image/imgSilder1.png"
import slider2 from "@components/pageEbook/ebooks/ebook16/image/imgSilder2.png"
import slider3 from "@components/pageEbook/ebooks/ebook16/image/imgSilder3.png"

const title = (
  <>
  <h1 className="titles-underlined-ebook ebook-ventas">
      8 tendencias de e-commerce para este 2024
    </h1>
  </>
)

const textDescription = (
  <p className="text-h2-ebook" style={{ marginTop: "20px" }}>
    El comercio electrónico ha democratizado la experiencia de compra en línea,
    pero es imperativo para las marcas mantenerse un paso adelante de las
    tendencias. La transición hacia 2024 se vislumbra con desafíos y
    oportunidades, desde fusiones y adquisiciones hasta la inteligencia
    artificial.
    <br />
    <br />A medida que el comercio minorista entra en su período más activo,
    estas tendencias serán determinantes para brindar a los negocios de comercio
    electrónico una ventaja adicional.
  </p>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
    <br />
    <span> ¡Descuida que te contactaremos solo por cosas importantes!</span>
  </span>
)

const textOne = (
  <p>
    La personalización en el comercio electrónico tiene beneficios
    significativos, como un aumento promedio del 20% en las ventas y un 80% de
    probabilidad de que los clientes compren en empresas que ofrecen
    experiencias personalizadas.
    <br /> <br />
    Este enfoque no solo ayuda a los clientes a lograr sus objetivos al
    interactuar con la marca, sino que también se alinea con los objetivos
    comerciales, generando impactos medibles como conversiones y crecimiento de
    ingresos.
  </p>
)
const textTwo = (
  <p>
    Sorprendentemente, el 77.24% de los compradores abandonan sus carritos antes
    de concluir una compra, indicando la necesidad de persuadir a los clientes
    para que completen sus elecciones en línea.
    <br /> <br />
    La realidad virtual, junto con la realidad aumentada, se vislumbra como una
    tecnología emergente capaz de transformar el panorama, con aproximadamente
    un tercio de los clientes demandando más inversión en estas tecnologías para
    enriquecer su experiencia.
  </p>
)
const textThree = (
  <p>
    Se proyecta que, para finales de 2024, el 75% de la población mundial estará
    amparada por regulaciones modernas de privacidad de datos, según las
    predicciones de Gartner.
  </p>
)

const data = {
  start: {
    support: "Ebook | Ecommerce",
    title: title,
    description: textDescription,
    classtext: "",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Ebook",
    img: imgPortadaEbook,
  },

  slider: {
    title: true,
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Mayor uso de la inteligencia artificial",
      },
      {
        key: 2,
        text: "Ventas asistidas de up-selling y cross-selling",
      },
      {
        key: 3,
        text: "Atención al cliente con enfoque omnicanal",
      },
      {
        key: 4,
        text: "Automatizaciones digitales",
      },
      {
        key: 5,
        text: "Beneficios de personalizar la atención al cliente",
      },
      {
        key: 6,
        text: "Tendencias que marcarán el camino al éxito",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        title: "Personalización en la atención y productos",
        text: textOne,
        photo: img1,
        class: true,
      },
      {
        title: "Uso de la realidad virtual para compras  ",
        text: textTwo,
        photo: img2,
        class: "",
      },
      {
        title: "Preferencias de privacidad para los usuarios ",
        text: textThree,
        photo: img3,
        class: true,
      },
    ],
  },
}

export default data
